import React from 'react'
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from 'prop-types'
import closeButton from '../assets/images/closeButton.png'
import arrowWhite from '../assets/images/arrow-white.png'
import arrowGreen from '../assets/images/arrow-green.png'

import { dismissToast } from "../actions/accounts"

const propTypes = {
  message: PropTypes.string.isRequired,
  messageType: PropTypes.string,
}

class Toast extends React.Component {

  dismiss () {
    this.props.dismissToast()
  }

  render () {
    if (this.props.message) {
      return (
        <div className={`toast__container ${this.props.messageType === 'success' ? "toast-success" : "toast-error"}`}>
          <img className={'arrow'} src={this.props.messageType === 'success' ? arrowGreen : arrowWhite} alt={'no-ref'}/>
          <div className={'toast__messageContent'}>
            {this.props.messageType === 'success' ?
              <p><strong>SUCCESS</strong></p>
              :
              <p><strong>ERROR</strong></p>
            }
            <p><small>{this.props.message}</small></p>
          </div>
          <span onClick={() => this.dismiss()}>
            <img className={'closeButton'} src={closeButton} alt={'no-ref'}/>
          </span>
        </div>
      )
    } else {
      return <div/>
    }
  }
}

Toast.propTypes = propTypes

export default compose(
  connect(
    null,
    { dismissToast }
  ),
)(Toast)
