import React from 'react'
import { connect } from 'react-redux'
import { logout } from '../actions/sessions'
import { Link } from 'react-router-dom'

import { version } from '../../package.json';

class SideMenu extends React.Component {
  render () {
    if (this.props.application.showSideMenu) {
      return (
        <div className="sideMenu">
          <div className="sideMenu__subTitle">
            <span className="appText text__grey text__ex_sm">MAIN NAVIGATION</span>
          </div>
          <div className="sideMenu__item">
            <Link to={"/"} className="sideMenu__item_title appText text__md">Incidents</Link>
          </div>
          <div className="sideMenu__subItem">
            <Link to={"/"} className="sideMenu__item_title appText text__md">Incidents</Link>
          </div>
          <div className="sideMenu__subItem">
            <Link to={"/new"} className="sideMenu__item_title appText text__md">New Incident</Link>
          </div>
          {(this.props.account.admin && this.props.account.admin.uuid) &&
          <div>
            <div className="sideMenu__item">
              <Link to={"/admin"} className="sideMenu__item_title appText text__md">Admin</Link>
            </div>
            <div className="sideMenu__subItem">
              <Link to={"/admin"} className="sideMenu__item_title appText text__md">All Incidents</Link>
            </div>
            <div className="sideMenu__subItem">
              <Link to={"/allUsers"} className="sideMenu__item_title appText text__md">All Accounts</Link>
            </div>
            <div className="sideMenu__subItem">
              <Link to={"/inviteUser"} className="sideMenu__item_title appText text__md">Invite User</Link>
            </div>
            <div className="sideMenu__subItem">
              <Link to={"/createSchool"} className="sideMenu__item_title appText text__md">Create School</Link>
            </div>
            <div className="sideMenu__subItem">
              <Link to={"/pendingInvitations"} className="sideMenu__item_title appText text__md">Pending Invitations</Link>
            </div>
            <div className="sideMenu__item_version">
              v{version}
            </div>
          </div>
          }
        </div>
      )
    } else {
      return (<div/>)
    }
  }
}

const mapStateToProps = state => {
  return { application: state.application, account: state.account }
}

export default connect(
  mapStateToProps,
  { logout }
)(SideMenu)
