import React from 'react'
import { Field } from 'redux-form'

class FormInput extends React.Component {
  renderInput = ({ input, placeholder, meta, width, title, flex = 1 }) => {
    const className = `reduxFormInput ${meta.error && meta.touched ? 'error' : ''}`
    return (
      <div className={className} style={{ width: width, flex }}>
        <input {...input} className="inputWithTitle" style={{ flex }} placeholder={title}/>
        {meta.error && meta.touched && <span>{this.renderError(meta)}</span>}
      </div>
    )
  }

  renderError ({ error, touched }) {
    if (touched && error) {
      return (
        <div className="formError__text">
          {error}
        </div>
      )
    }
  }

  render () {
    return (
      <Field
        component={this.renderInput}
        name={this.props.name}
        title={this.props.title}
        width={this.props.width}
        flex={this.props.flex}
      />
    )
  }
}

export default (FormInput)
