import React from "react"
import ReactExport from "react-data-export"

import { connect } from "react-redux"
import { downloadIncidents } from "../actions/incidents"
import { compose } from "redux"
import moment from "moment"
import _ from 'lodash'
import * as API from "../services/CONTEX-API"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn


class Download extends React.Component {
  constructor (props) {
    super(props)
    this.state ={
      DATA: null
    }
  }

  downloadIncidents = () => {
    this.props.downloadIncidents()
  }

  async generateReport () {
    let incidents = _.cloneDeep(this.props.incidents)
    let students = _.cloneDeep(this.props.students)
    let accounts = _.keyBy(_.cloneDeep(this.props.accounts), 'uuid')

    API.getAllSchools(this.props.sessionToken.sessionToken)
      .then(fetchedSchools => {
        let schools = _.keyBy(fetchedSchools, 'uuid')
        for (const key of Object.keys(incidents)) {
          let incident = incidents[key]
          incident['incidentDate'] = moment(incident['incidentDate']).format('DD/MM/YY')
          incident['firstEvaluation'] = moment(incident['firstEvaluation']).format('DD/MM/YY')
          incident['createdAt'] = moment(incident['createdAt']).format('DD/MM/YY')
          const schoolUuid = incident['schoolUuid']
          const accountUuid = incident['creatorUuid']
          incident = { ...students[incident['studentUuid']], ...schools[schoolUuid], ...accounts[accountUuid], ...incident }
          incidents[key] = incident
        }
        this.setState({ DATA: incidents })
      })
      .catch(error => console.error({ error }))
      .finally(() => this.downloadIncidents())
    return { incidents: [] }
  }


  render () {
    if (!this.props.students) return <div/>
    if (this.state.DATA) {
      return (
        <ExcelFile
          element={<div className={'linkText'} style={{ width: 'fit-content' }}
                      onClick={this.downloadIncidents.bind(this)}>Export
            to Excel</div>}>
          <ExcelSheet data={this.state.DATA} name="Incidents">
            <ExcelColumn label="id" value="uuid"/>
            <ExcelColumn label="date_of_incident" value="incidentDate"/>
            <ExcelColumn label="athlete_last_name" value="lastName"/>
            <ExcelColumn label="athlete_gender" value="gender"/>
            <ExcelColumn label="athlete_date_of_birth" value="dob"/>
            <ExcelColumn label="athlete_height" value="height"/>
            <ExcelColumn label="athlete_weight" value="weight"/>
            <ExcelColumn label="current_grade_level" value="grade"/>
            <ExcelColumn label="logged_by_id" value="creatorUuid"/>
            <ExcelColumn label="date_first_clinical_evaluation" value="firstEvaluation"/>
            <ExcelColumn label="athlete_years_in_sport" value="yearsInSport"/>
            <ExcelColumn label="athlete_number_history_concussion" value="numberOfPriorConcussions"/>
            <ExcelColumn label="athlete_history_loss_consciousness" value="lossOfConsciousness"/>
            <ExcelColumn label="athlete_wear_helmet" value="wearingHelmet"/>
            <ExcelColumn label="field_location" value="field"/>
            <ExcelColumn label="ethnicity" value="ethnicity"/>
            <ExcelColumn label="event_type" value="event"/>
            <ExcelColumn label="race" value="race"/>
            <ExcelColumn label="level_of_competition" value="teamLevel"/>
            <ExcelColumn label="logged_by" value="creatorUuid"/>
            <ExcelColumn label="loss_consciousness" value="lossOfConsciousness"/>
            <ExcelColumn label="sport_name" value="sport"/>
            <ExcelColumn label="school_name" value="schoolName"/>
            <ExcelColumn label="school_city" value="schoolCity"/>
            <ExcelColumn label="school_district" value="districtName"/>
            <ExcelColumn label="user_email" value="email"/>
          </ExcelSheet>
        </ExcelFile>
      )
    } else {
      return <div style={{ width: 'fit-content', color: 'blue' }} onClick={this.generateReport.bind(this)}>Load Data for Export</div>
    }
  }
}


export default compose(
  connect(
    null,
    { downloadIncidents }
  )
)(Download)
