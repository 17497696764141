import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { fetchIncidents, filterIncidentsByDate } from '../actions/incidents'
import { fetchStudents } from "../actions/students"

import history from '../history'
import IncidentsTable from "../components/IncidentsTable"

class IncidentList extends React.Component {
  componentDidMount () {
    if (this.props.sessionToken) {
      const sessionToken = this.props.sessionToken.sessionToken
      this.props.fetchIncidents(sessionToken, this.props.account.uuid)
      this.props.fetchStudents(sessionToken)
    }
  }

  render () {
    if (!this.props.sessionToken || !this.props.sessionToken.sessionToken) history.push("/login")
    return <IncidentsTable
      incidents={this.props.incidents}
      students={this.props.students}
      filterIncidentsByDate={this.props.filterIncidentsByDate}
      history={this.props.history}
    />
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    incidents: state.incidents,
    paginatedIncidents: state.paginatedIncidents,
    sessionToken: state.sessionToken,
    students: state.students
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      fetchIncidents,
      fetchStudents,
      filterIncidentsByDate
    }
  )
)(IncidentList)
