import React from 'react'
import { reduxForm, Field, change } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { inviteUser } from "../actions/accounts"



import Toast from '../components/Toast'

class AdminInviteUserPage extends React.Component {
  onSubmit = formProps => {
    this.props.inviteUser(formProps, () => {
      this.props.change('email', null)
    })
  }

  renderError ({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      )
    }
  }

  renderInput = ({ input, placeholder, meta, id }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`
    return (
      <div className={className}>
        <input
          {...input}
          placeholder={placeholder}
          autoComplete="off"
          className="formInput"
          id={id}
        />
        {this.renderError(meta)}
      </div>
    )
  }

  render () {
    const { handleSubmit } = this.props
    return (
        <form className="createUser" onSubmit={handleSubmit(this.onSubmit)}>
          {this.props.toast.message &&
            <Toast message={this.props.toast.message} messageType={this.props.toast.messageType}/>
          }
          <div className="appTitle signUpTitle">
            <p>Invite User</p>
            <small>Fill out the form below to create an account for a new user. A email will be sent to the provided
              email address where the recipient of the email will receive instructions on how to set their password.
            </small>
          </div>
          <div className="form-group">
            <Field
              name="email"
              component={this.renderInput}
              placeholder="Email Address"
              id="emailinput"
            />
          </div>
          <div className={'formButtonContainer'}>
            <br/>
            <button className="button button__disabled">Email Invite</button>
          </div>
        </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    toast: state.toast
  }
}

export default compose(
  connect(
    mapStateToProps,
    { inviteUser, change }
  ),
  reduxForm({ form: 'invite' })
)(AdminInviteUserPage)
