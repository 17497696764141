import * as API from '../services/CONTEX-API'

export const ADMIN_DOWNLOAD_INCIDENTS = 'ADMIN_DOWNLOAD_INCIDENTS'
export const CLEAR_INCIDENT_ERROR = 'CLEAR_INCIDENT_ERROR'
export const CREATE_INCIDENT = 'CREATE_INCIDENT'
export const FILTER_INCIDENTS = 'FILTER_INCIDENTS'
export const REQUEST_INCIDENT = 'REQUEST_INCIDENT'
export const REQUEST_INCIDENTS = 'REQUEST_INCIDENTS'
export const SET_INCIDENT = 'SET_INCIDENT'
export const SET_INCIDENTS = 'SET_INCIDENTS'
export const INCIDENT_REQUEST_FAILED = 'INCIDENT_REQUEST_FAILED'
export const UPDATE_INCIDENT = 'UPDATE_INCIDENT'

export function fetchIncidents (sessionToken, userUuid) {
  return function (dispatch, getState) {
    dispatch(requestIncidents())

    API.getIncidentsByUserID(sessionToken, userUuid)
      .then(incidents => {
        dispatch(setIncidents(incidents))
        dispatch(clearIncidentError())
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function fetchAllIncidents (sessionToken, userID) {
  return function (dispatch, getState) {
    dispatch(requestIncidents())

    API.getIncidents(sessionToken)
      .then(incidents => {
        dispatch(setIncidents(incidents))
        dispatch(clearIncidentError())
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function fetchIncident (sessionToken, userID, incidentID) {
  return function (dispatch, getState) {
    dispatch(requestIncident())

    API.getIncident(sessionToken, userID, incidentID)
      .then(incident => {
        dispatch(setIncident(incident))
        dispatch(clearIncidentError())
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function createIncident (sessionToken, body, callback) {
  return function (dispatch, getState) {
    dispatch(requestCreate())

    API.createIncident(sessionToken, body)
      .then(incident => {
        dispatch(setIncident(incident))
        dispatch(clearIncidentError())
        callback()
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function updateIncident (sessionToken, incidentID, body, callback) {
  return function (dispatch, getState) {
    dispatch(requestUpdate())

    API.updateIncident(sessionToken, incidentID, body)
      .then(incident => {
        dispatch(setIncident(incident))
        dispatch(clearIncidentError())
        callback()
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function filterIncidentsByDate (startDate, endDate) {
  console.log(startDate, endDate)
  return function (dispatch, getState) {
    dispatch(requestFilterIncidents())

    const start = new Date(startDate).getTime()
    const end = new Date(endDate).getTime()

    const result = getState().incidents.filter(d => {
      const time = new Date(d.incidentDate).getTime()
      return (start < time && time < end)
    })

    dispatch(setIncidents(result))
    dispatch(clearIncidentError())
  }
}

export function requestFilterIncidents () {
  return {
    type: FILTER_INCIDENTS,
    requestedAt: Date.now()
  }
}

export function downloadIncidents () {
  return {
    type: ADMIN_DOWNLOAD_INCIDENTS,
    requestedAt: Date.now()
  }
}

export function requestUpdate () {
  return {
    type: UPDATE_INCIDENT,
    requestedAt: Date.now(),
  }
}

export function requestCreate () {
  return {
    type: CREATE_INCIDENT,
    requestedAt: Date.now(),
  }
}

export function requestIncidents () {
  return {
    type: REQUEST_INCIDENTS,
    requestedAt: Date.now(),
  }
}

export function setIncidents (incidents) {
  return {
    type: SET_INCIDENTS,
    incidents,
    receivedAt: Date.now(),
  }
}

export function requestIncident () {
  return {
    type: REQUEST_INCIDENT,
    requestedAt: Date.now(),
  }
}

export function setIncident (incident) {
  return {
    type: SET_INCIDENT,
    incident,
    receivedAt: Date.now(),
  }
}

export function requestFailed (status) {
  console.info(status)
  return {
    type: INCIDENT_REQUEST_FAILED,
    status,
    received_at: Date.now(),
  }
}

export function clearIncidentError () {
  return {
    type: CLEAR_INCIDENT_ERROR,
    receivedAt: Date.now(),
  }
}
