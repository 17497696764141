import * as API from '../services/CONTEX-API'

export function fetchInvitations (token) {
  return function (dispatch) {
    API.fetchInvitations(token)
      .then(invitations => {
        dispatch(setInvitations(invitations))
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}
export function deleteInvitation (uuid, token) {
  return function (dispatch) {
    API.deleteInvitation(uuid, token)
      .then(invitations => {
        dispatch(deletedInvitation)
        dispatch(fetchInvitations(token))
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}

export function resendInvitation (uuid, token) {
  return function (dispatch) {
    API.resendInvitation(uuid, token)
      .then(invitations => {
        dispatch(resentInvitation)
        dispatch(fetchInvitations(token))
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}


export function setInvitations (invitations) {
  return {
    type: 'SET_INVITATIONS',
    invitations,
    received_at: Date.now()
  }
}

export function deletedInvitation () {
  return {
    type: 'DELETE_INVITATION',
    received_at: Date.now()
  }
}

export function resentInvitation () {
  return {
    type: 'RESEND_INVITATION',
    received_at: Date.now()
  }
}

export function requestFailed (status) {
  console.info(status)
  return {
    type: 'INVITATIONS_REQUEST_FAILED',
    status,
    received_at: Date.now(),
  }
}
