import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { createFollowUp } from "../actions/followUps"

import FormInput from './FormInput'

class FollowUpModal extends React.Component {
  onSubmit = formProps => {
    this.props.createFollowUp(this.props.sessionToken.sessionToken, formProps, this.props.incidentUuid)
    this.props.onHide()
  }

  chooseRTP = event => {
    this.props.change('returnToPlay', event.target.value)
    this.setState({ returnToPlay: event.target.value })
  }

  renderRTPDropDown = ({ input, label, width, title }) => {
    return (
      <div className={"pickerInput"}>
        <select
          className="dropdownInput"
          style={{ width }}
          onChange={this.chooseRTP}
        >
          <option>Choose...</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <span className="inputWithTitle__title">{title}</span>
      </div>
    )
  }

  chooseCloseInjury = event => {
    this.props.change('close', event.target.value)
    this.setState({ close: event.target.value })
  }

  renderCloseInjuryDropDown = ({ input, label, width, title }) => {
    return (
      <div className={"pickerInput"}>
        <select
          className="dropdownInput"
          style={{ width }}
          onChange={this.chooseCloseInjury}
        >
          <option>Choose...</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <span className="inputWithTitle__title">{title}</span>
      </div>
    )
  }

  render () {
    const { handleSubmit } = this.props

    return (
      <div className="modal">
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="modal__header">
            <div style={{ flex: 1 }}>
              <span className="modal__headerText">Add Follow Up</span>
              <span className="modal__headerSubText">Incident # {this.props.incidentUuid}</span>
            </div>
            <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}>
              <button className="modal__closeButton" onClick={e => {
                  e.preventDefault()
                  this.props.onHide()
                }}>x</button>
            </div>
          </div>
          <div className="modal__bodyContent">
            <div className="modal__formGroup">
              <span className="modal__inputLabel">Is the student cleared to return to play?</span>
              <Field
                name="returnToPlay"
                width={'200px'}
                component={this.renderRTPDropDown}
                placeholder="Athlete cleared to play?"
                id="returnToPlay"
              />
            </div>
            <div className="modal__formGroup">
              <span className="modal__inputLabel">What is your reason for this decision?</span>
            </div>
            <div className="modal__formGroup">
              <FormInput name={'reason'} title={'Reason for return to play decision'} width={'100%'}/>
            </div>
            <div className="modal__formGroup">
              <span className="modal__inputLabel">Would you like to close this injury?</span>
              <Field
                name="close"
                width={'200px'}
                component={this.renderCloseInjuryDropDown}
                placeholder="Close?"
                id="close"
              />
            </div>
            <div className="modal__ctaContainer">
              <button className="button">Submit</button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    incidents: state.incidents,
    sessionToken: state.sessionToken
  }
}

export default compose(
  connect(
    mapStateToProps,
    { createFollowUp }
  ),
  reduxForm({ form: 'followupmodal' })
)(FollowUpModal)
