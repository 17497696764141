import { applyMiddleware, compose, createStore } from "redux"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers/index'
import thunkMiddleware from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['incidents']
}

const pReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(pReducer, composeEnhancers(applyMiddleware(thunkMiddleware)))
export const persistor = persistStore(store)
