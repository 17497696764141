import _ from 'lodash'

export default function(state = [], action) {
  switch (action.type) {
    case 'SET_ADMINS':
      return _.keyBy(action.admins, 'accountUuid')
    case 'CLEAR_ACCOUNTS':
      return null
    default:
      return state
  }
}
