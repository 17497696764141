import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { fetchAccounts } from "../actions/accounts"
import { toggleHeader, toggleSideMenu } from "../actions/application"

import Pagination from '../components/Pagination'
import AccountRow from "../components/AccountRow"

class IncidentList extends React.Component {
  constructor () {
    super()

    this.state = {
      loading: true,
      pageOfItems: []
    }

    this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount () {
    const sessionToken = this.props.sessionToken.sessionToken
    this.props.fetchAccounts(sessionToken)
    this.setState({ loading: this.props.accounts.length > 0 })
  }

  onChangePage (pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  renderTable () {
    return (
      <div>
        <table className="">
          <thead className="incidentsTableHeader">
          <tr>
            <th scope="col">ROS USER</th>
            <th scope="col">EMAIL</th>
            <th scope="col">PHONE</th>
            <th scope="col">ACCESS LEVEL</th>
            <th scope="col">DISABLED</th>
            <th scope="col">ACTION</th>
            <th scope="col">PASSWORD</th>
          </tr>
          </thead>
          <tbody>
          {this.state.pageOfItems.map((account, i) =>
            <AccountRow key={i} indexId={i} account={account} />
          )}
          </tbody>
        </table>
        <Pagination items={this.props.accounts} onChangePage={this.onChangePage}/>
      </div>
    )
  }

  render () {
    return (
      <div className="incidentsContainer">
        <div className="incidentsTable">{this.renderTable()}</div>
      </div>
    )
  }
}

const mapStateToProps = ({ accounts, admins, paginatedIncidents, sessionToken, students }) => {
  return { accounts, admins, paginatedIncidents, sessionToken, students }
}

export default compose(
  connect(
    mapStateToProps,
    { fetchAccounts, toggleSideMenu, toggleHeader }
  )
)(IncidentList)
