import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Row from "./Row"
import Pagination from "./Pagination"
import _ from "lodash"
import DatePicker from "react-datepicker"

class IncidentsTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pageOfItems: []
    }
    this.onChangePage = this.onChangePage.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.incidents.length > 0) {
      const today = new Date()
      this.setState({
        startDate: nextProps.incidents[nextProps.incidents.length - 1].incidentDate,
        endDate: today.toISOString()
      })
    }
    this.setState({
      loading: nextProps.incidents.length > 0,
    })
  }

  onChangePage (pageOfItems) {
    pageOfItems = _.filter(pageOfItems, o => o.incidentDate >= this.state.startDate && o.incidentDate <= this.state.endDate)
    this.setState({ pageOfItems: pageOfItems })
  }

  filterStartDate (startDate) {
    this.setState({ startDate })
    this.props.filterIncidentsByDate(startDate, this.state.endDate)
  }

  filterEndDate (endDate) {
    this.setState({ endDate })
    this.props.filterIncidentsByDate(this.state.endDate, endDate)
  }

  render () {
    if (!this.props.incidents.length) return <div/>
    return (
      <div className="incidentsContainer">
        <div className="incidentsTable">
          <div style={{
            flex: 1,
            flexDirection: 'row',
            display: 'inline-flex',
            width: '100%',
            justifyContent: 'flex-end'
          }}>
            <div style={{ width: '100%', paddingTop: 25 }}>
              <h3>All Incidents</h3>
            </div>
            <div style={{ width: 100, overflow: 'hidden', margin: 8 }}>
              <DatePicker dateForm="MM-DD-YYYY" selected={this.state.startDate ? new Date(this.state.startDate) : null}
                          onChange={(value) => this.filterStartDate(value.toISOString())}/>
              <span className="inputWithTitle__title">Filter From</span>
            </div>
            <div style={{ width: 100, overflow: 'hidden', margin: 8, marginRight: 0 }}>
              <DatePicker dateForm="MM-DD-YYYY" selected={this.state.endDate ? new Date(this.state.endDate) : null}
                          onChange={(value) => this.filterEndDate(value.toISOString())}/>
              <span className="inputWithTitle__title">Filter To</span>
            </div>
          </div>
          <table className="">
            <thead className="incidentsTableHeader">
            <tr>
              <th scope="col">PEIMS</th>
              <th scope="col">ATHLETE</th>
              <th scope="col">DATE OF BIRTH</th>
              <th scope="col">INCIDENT DATE</th>
              <th scope="col">STATUS</th>
            </tr>
            </thead>
            <tbody>
            {this.state.pageOfItems.map((item, i) =>
              <Row item={item} student={this.props.students[item.studentUuid]} indexId={i} key={i}
                   history={this.props.history}/>
            )}
            </tbody>
          </table>
          <Pagination items={this.props.incidents} onChangePage={this.onChangePage}/>
        </div>
      </div>
    )
  }
}

IncidentsTable.propTypes = {
  // incidents: PropTypes.object,
  // students: PropTypes.object
}

export default IncidentsTable

