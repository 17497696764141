export default function (state = [], action) {
  switch (action.type) {
    case 'SET_INCIDENT':
      return { ...state, activeIncident: action.incident }
    case 'SET_SCHOOL_TO_ACTIVE_INCIDENT':
      return { ...state, activeIncident: { ...state.activeIncident, school: action.school } }
    case 'SET_INCIDENTS':
      return action.incidents
    case 'FETCH_INCIDENTS':
      return [action.payload]
    case 'FETCH_INCIDENT':
      return action.payload
    default:
      return state
  }
}
