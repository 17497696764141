const sessionToken = (state = null, action) => {
  switch(action.type) {
    case 'SET_SESSION_TOKEN':
      return { sessionToken: action.sessionToken }
    case 'SET_SESSION_TOKEN_FAILED':
      return { errorMessage: action.authorization.message }
    case 'SESSION_TOKEN_REQUEST_FAILED':
      return { errorMessage: action.status.message }
    case 'DESTROY_SESSION_TOKEN':
      return null
    default:
      return state
  }
}

export default sessionToken
