import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import _ from 'lodash'

import { fetchAllIncidents, filterIncidentsByDate } from '../actions/incidents'
import { fetchStudents } from "../actions/students"
import { toggleHeader, toggleSideMenu } from "../actions/application"

import ExcelExporter from '../components/ExcelExporter'
import IncidentsTable from "../components/IncidentsTable"

class IncidentList extends React.Component {
  componentDidMount () {
    if (this.props.sessionToken) {
      const sessionToken = this.props.sessionToken.sessionToken
      this.props.fetchAllIncidents(sessionToken, this.props.account.uuid)
      this.props.fetchStudents(sessionToken)
    }
  }

  render () {
    return (
      <div style={{ flex: 1, flexDirection: 'column' }}>
        <div
          style={{ flex: 1, flexDirection: 'row', display: 'inline-flex', width: '100%', justifyContent: 'flex-end' }}>
          <ExcelExporter incidents={this.props.incidents} students={this.props.students} accounts={this.props.accounts}
                         sessionToken={this.props.sessionToken}/>
        </div>
        <IncidentsTable
          incidents={this.props.incidents}
          students={this.props.students}
          filterIncidentsByDate={this.props.filterIncidentsByDate}
          history={this.props.history}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ account, incidents, paginatedIncidents, sessionToken, students, accounts }) => {
  incidents = _.sortBy(incidents, 'incidentDate').reverse()
  return { account, incidents, paginatedIncidents, sessionToken, students, accounts }
}

export default compose(
  connect(
    mapStateToProps,
    { fetchAllIncidents, fetchStudents, filterIncidentsByDate, toggleSideMenu, toggleHeader }
  )
)(IncidentList)
