const sessionToken = (state = {}, action) => {
  switch (action.type) {
    case 'EMAIL_INVITE_SUCCESS':
      return { message: action.message, messageType: 'success' }
    case 'EMAIL_INVITE_FAILURE':
      return { message: action.message, messgeType: 'error' }
    case 'INCIDENT_REQUEST_FAILED':
      return { ...state, submitError: action.status }
    case 'CLEAR_INCIDENT_ERROR':
      return { ...state, submitError: null }
    case 'CLEAR_TOAST':
      return {}
    default:
      return state
  }
}

export default sessionToken
