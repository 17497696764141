import React from 'react'

export default class InputSchool extends React.Component {
  renderSchools = (schools) => {
    return schools.map(school => {
      let toRender
      toRender = (<div className={"schoolCard"} key={school.uuid}>
        <span className={"schoolCard"} onClick={() => {
          this.props.chooseSchool(school)
        }}>
        {school.schoolName}
        </span>
      </div>)
      return toRender
    })
  }

  renderSchoolsReset = () => {
    return (<span className={"schoolsReset"} onClick={() => this.props.chooseSchool(undefined)}>reset</span>)
  }

  render () {
    return (
      <div style={{ flex: 1 }}>
        <div className="reduxFormRow">
          <div className={'reduxFormInput'} style={{ flex: 1 }}>
            <input
              value={this.props.inputText}
              autoComplete={"off"}
              className="inputWithTitle"
              onChange={(value) => this.props.searchSchools(value.target.value)} />
            <span className="inputWithTitle__title">STUDENT'S SCHOOL</span>
          </div>
        </div>
        <div className={"schoolsContainer"}>
          {this.props.school === null ? this.renderSchools(this.props.schools) : this.renderSchoolsReset()}
        </div>
      </div>
    )
  }
}
