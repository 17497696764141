import * as API from '../services/CONTEX-API'
export const SET_SCHOOLS = 'SET_SCHOOLS'
export const SET_SCHOOL_TO_ACTIVE_INCIDENT = 'SET_SCHOOL_TO_ACTIVE_INCIDENT'
export const REQUEST_SCHOOLS = 'REQUEST_SCHOOLS'
export const SCHOOLS_REQUEST_FAILED = 'SCHOOLS_REQUEST_FAILED'

export function createSchool (sessionToken, formProps, callback) {
  return function (dispatch) {
    API.createSchool(sessionToken, formProps)
      .then(response => {
        // dispatch(login(formProps, callback))
        callback()
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function fetchSchools (name) {
  return function (dispatch, getState) {
    dispatch(requestSchools())

    if (!getState().sessionToken) return dispatch(requestFailed('No Session Token'))

    API.getSchools(name, getState().sessionToken.sessionToken)
      .then(schools => {
        dispatch(setSchools(schools))
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function getSchool (sessionToken, uuid) {
  return function (dispatch) {
    dispatch(requestSchools())

    if (!sessionToken) return dispatch(requestFailed('No Session Token'))

    API.getSchool(sessionToken, uuid)
      .then(school => {
        dispatch(setSchoolToActiveIncident(school))
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function requestSchools () {
  return {
    type: REQUEST_SCHOOLS,
    requestedAt: Date.now(),
  }
}

export function setSchools (schools) {
  return {
    type: SET_SCHOOLS,
    schools,
    receivedAt: Date.now(),
  }
}

export function setSchoolToActiveIncident (school) {
  return {
    type: SET_SCHOOL_TO_ACTIVE_INCIDENT,
    school,
    receivedAt: Date.now(),
  }
}

export function requestFailed (status) {
  console.info(status)
  return {
    type: SCHOOLS_REQUEST_FAILED,
    status,
    received_at: Date.now(),
  }
}
