import React from 'react'
import { Modal } from 'react-bootstrap'

import FollowUpModal from './FollowUpModal'
import addIcon from "../assets/images/addIcon.png"

export default class FollowUpContainer extends React.Component {
  render () {
    const { followUps } = this.props
    return (
      <div className="followUpsContainer">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <h3 style={{ lineHeight: '50px' }}>Follow Ups</h3>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} id="addIcon" onClick={this.props.onShow}>
            <div style={{ margin: '0px 10px' }}>
              <img style={{ position: 'relative', maxHeight: 40, maxWidth: 40, justifyContent: 'center', alignItems: 'center' }} src={addIcon} alt={'no-ref'} />
            </div>
          </div>
        </div>
        <table className="table table-hover">
          <thead className="thead-light">
          <tr>
            <th scope="col">Created At</th>
            <th scope="col">Reason</th>
            <th scope="col">Return To Play</th>
            <th scope="col">Updated At</th>
          </tr>
          </thead>
          <tbody>
          {followUps && followUps.length > 0 && followUps.map(row => {
            return (
              <tr key={row.uuid}>
                <th>{row.createdAt}</th>
                <th>{row.reason}</th>
                <th>{row.returnToPlay}</th>
                <th>{row.updatedAt}</th>
              </tr>
            )
          })}
          </tbody>
        </table>

        <Modal show={this.props.show}>
          <FollowUpModal incidentUuid={this.props.incidentUuid} onHide={this.props.onHide}/>
        </Modal>
      </div>
    )
  }
}
