import _ from 'lodash'

export default function(state = [], action) {
  switch (action.type) {
    case 'SET_STUDENTS':
      return _.keyBy(action.students, 'uuid')
    default:
      return state
  }
}
