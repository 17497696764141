import * as API from "../services/CONTEX-API"

export function fetchFollowUps (sessionToken, incidentId) {
  return function (dispatch) {
    dispatch(requestFollowUps())

    API.getFollowUps(sessionToken, incidentId)
      .then(followUps => {
        dispatch(setFollowUps(followUps))
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function createFollowUp (sessionToken, formProps, incidentUuid) {
  return function (dispatch) {
    API.createFollowUp(sessionToken, formProps, incidentUuid)
      .then(response => {
        console.log(response)
        fetchFollowUps(sessionToken, incidentUuid)
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function setFollowUps (followUps) {
  return {
    type: 'SET_FOLLOW_UPS',
    followUps,
    requestedAt: Date.now()
  }
}

export function requestFollowUps () {
  return {
    type: 'REQUEST_FOLLOW_UPS',
    requestedAt: Date.now(),
  }
}

export function requestFailed (status) {
  console.info(status)
  return {
    type: 'REQUEST_FOLLOW_UPS_FAILED',
    status,
    received_at: Date.now(),
  }
}