import React from 'react'
import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createAccount } from "../actions/accounts"
import { logoutNoRedirect } from "../actions/sessions"
import { Link } from 'react-router-dom'

import background from '../assets/images/photo-1431324155629-1a6deb1dec8d.jpeg'

import FormInput from '../components/FormInput'

class SignUp extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null
    }
  }

  componentDidMount () {
    if (this.props.sessionToken) this.props.logoutNoRedirect()
    console.log(this.props.match)
  }

  onSubmit = formProps => {
    const { invitationCode, email } = this.props.match.params
    if (!invitationCode) {
      this.setState({ error: 'No Invitation Code Present' })
    } else {
      this.props.createAccount(formProps, email, invitationCode, () => {
        this.props.history.push('/login')
      })
    }
  }

  renderError ({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      )
    }
  }

  renderInput = ({ input, placeholder, meta, id }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`
    return (
      <div className={className}>
        <input
          {...input}
          placeholder={placeholder}
          autoComplete="off"
          className="formInput"
          id={id}
        />
        {this.renderError(meta)}
      </div>
    )
  }

  render () {
    const { handleSubmit } = this.props
    return (
      <div className="imageBackground imageBackground__signUp" style={{ backgroundImage: `url(${background})` }}>
        <div className="overlay__blue">
          <form className="formContainer signUpContainer" onSubmit={handleSubmit(this.onSubmit)}>
            <div className="appTitle signUpTitle">
              <p>Welcome, {this.props.match.params.email}</p>
              <span>Complete your account below</span>
            </div>
            <div style={{ flex: 8, display: 'flex', flexDirection: 'column' }}>
              <div className="signUpFormInputRow">
                <FormInput
                  name="firstName"
                  title="First Name"
                  width={'45%'}
                  id="firstNameInput"
                />
                <FormInput
                  name="lastName"
                  title="Last Name"
                  width={'45%'}
                  id="lastNameInput"
                />
              </div>
              <div className="signUpFormInputRow">
                <FormInput
                  name="password"
                  title="Password (min 8 characters)"
                  width={'45%'}
                  id="passwordinput"
                />
                <FormInput
                  name="confirmPassword"
                  title="Confirm Password"
                  width={'45%'}
                  id="passwordinput"
                />
              </div>
              <div className={'formButtonContainer'}>
                {!!this.state.error &&
                <div>
                  <h4>{this.state.error}</h4>
                </div>
                }
                <button className="button button__disabled" style={{ marginTop: 35 }}>Sign Up</button>
                <Link to="/login" className="linkText authLink">
                  Already have an account?
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const validate = formValues => {
  const errors = {}


  return errors
}

const mapStateToProps = (state) => {
  return {
    sessionToken: state.sessionToken
  }
}

export default compose(
  connect(
    mapStateToProps,
    { createAccount, logoutNoRedirect }
  ),
  reduxForm({ form: 'signup', validate })
)(SignUp)
