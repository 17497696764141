import React from 'react'
import { connect } from 'react-redux'

import { fetchFollowUps } from '../actions/followUps'
import { fetchIncident, updateIncident } from '../actions/incidents'
import { fetchStudents } from '../actions/students'

import FollowUpContainer from "../components/FollowUpContainer"
import IncidentForm from '../components/Incident'

class IncidentDetail extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false
    }
  }
  componentDidMount () {
    const { uuid } = this.props.match.params

    this.props.fetchIncident(
      this.props.sessionToken.sessionToken,
      this.props.account.uuid,
      uuid
    )

    this.props.fetchStudents(this.props.sessionToken.sessionToken)
    this.props.fetchFollowUps(this.props.sessionToken.sessionToken, uuid)
  }

  onSubmit = formProps => {
    this.props.updateIncident(
      this.props.sessionToken.sessionToken,
      this.props.incident.uuid,
      { ...formProps },
      () => this.props.history.push('/')
    )
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  handleShow = () => {
    this.setState({ show: true })
  }

  render () {
    const { incident, followups, values } = this.props
    if (incident) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <IncidentForm onSubmit={this.onSubmit} values={values} />
          <FollowUpContainer followUps={followups} onShow={this.handleShow} onHide={this.handleClose} show={this.state.show}
                             incidentUuid={this.props.incident.uuid}/>
        </div>
      )
    } else {
      return <div/>
    }
  }
}

const mapStateToProps = state => {
  const incident = state.incidents ? state.incidents.activeIncident : {}
  const student = incident ? state.students[incident.studentUuid] : {}
  const values = { ...incident, ...student }
  return {
    account: state.account,
    sessionToken: state.sessionToken,
    incident,
    followups: state.followups,
    values
  }
}

export default
  connect(
    mapStateToProps,
    { fetchIncident, fetchStudents, fetchFollowUps, updateIncident }
  )(IncidentDetail)
