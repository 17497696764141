import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSchool } from "../actions/schools"

class AdminCreateSchoolPage extends React.Component {
  onSubmit = formProps => {
    this.props.createSchool(this.props.sessionToken.sessionToken, formProps, () => {
      // this.props.history.push('/')
      console.log('DONE');
    })
  }

  renderError ({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      )
    }
  }

  renderInput = ({ input, placeholder, meta, id }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`
    return (
      <div className={className}>
        <input
          {...input}
          placeholder={placeholder}
          autoComplete="off"
          className="formInput"
          id={id}
        />
        {this.renderError(meta)}
      </div>
    )
  }

  render () {
    const { handleSubmit } = this.props
    return (
        <form className="createUser" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="appTitle signUpTitle">
            <p>Create School</p>
            <small>Fill out the form below to create an account for a new school.
            </small>
          </div>
          <div className="createSchoolInputs">
            <div style={{ marginRight: 30 }}>
              <div className="form-group">
                <div className="sectionTitle">
                  <p className="appText text__md createSchoolSectionText">County Information</p>
                </div>
                <Field
                  name="countyNumber"
                  component={this.renderInput}
                  placeholder="County Number"
                  id="countyNumber"
                />
                <Field
                  name="countyName"
                  component={this.renderInput}
                  placeholder="County Name"
                  id="countyName"
                />
              </div>
              <div className="form-group">
                <div className="sectionTitle">
                  <p className="appText text__md createSchoolSectionText">Region Information</p>
                </div>
                <Field
                  name="regionNumber"
                  component={this.renderInput}
                  placeholder="Region Number"
                  id="regionNumber"
                />
              </div>
              <div className="group-info">
                <div className="sectionTitle">
                  <p className="appText text__md createSchoolSectionText">School Information</p>
                </div>
                <Field
                  name="schoolNumber"
                  component={this.renderInput}
                  placeholder="School Number"
                  id="schoolNumber"
                />
                <Field
                  name="schoolName"
                  component={this.renderInput}
                  placeholder="School Name"
                  id="schoolName"
                />
                <Field
                  name="instructionType"
                  component={this.renderInput}
                  placeholder="Instruction Type"
                  id="instructionType"
                />
                <Field
                  name="charterType"
                  component={this.renderInput}
                  placeholder="Charter Type"
                  id="charterType"
                />
                <Field
                  name="schoolStreetAddress"
                  component={this.renderInput}
                  placeholder="School Street Address"
                  id="schoolStreetAddress"
                />
                <Field
                  name="schoolCity"
                  component={this.renderInput}
                  placeholder="School City"
                  id="schoolCity"
                />
                <Field
                  name="schoolState"
                  component={this.renderInput}
                  placeholder="School State"
                  id="schoolState"
                />
                <Field
                  name="schoolZip"
                  component={this.renderInput}
                  placeholder="School Zip"
                  id="schoolZip"
                />
                <Field
                  name="schoolPhone"
                  component={this.renderInput}
                  placeholder="School Phone"
                  id="schoolPhone"
                />
              </div>
            </div>
            <div style={{ marginLeft: 30 }}>
              <div className="form-group">
                <div className="sectionTitle">
                  <p className="appText text__md createSchoolSectionText">District Information</p>
                </div>
                <Field
                  name="districtNumber"
                  component={this.renderInput}
                  placeholder="District Number"
                  id="districtNumber"
                />
                <Field
                  name="districtName"
                  component={this.renderInput}
                  placeholder="District Name"
                  id="districtName"
                />
                <Field
                  name="districtType"
                  component={this.renderInput}
                  placeholder="District Type"
                  id="districtType"
                />
                <Field
                  name="districtStreetAddress"
                  component={this.renderInput}
                  placeholder="District Street Address"
                  id="districtStreetAddress"
                />
                <Field
                  name="districtCity"
                  component={this.renderInput}
                  placeholder="District City"
                  id="districtCity"
                />
                <Field
                  name="districtState"
                  component={this.renderInput}
                  placeholder="District State"
                  id="districtState"
                />
                <Field
                  name="districtZip"
                  component={this.renderInput}
                  placeholder="District Zip"
                  id="districtZip"
                />
                <Field
                  name="districtPhone"
                  component={this.renderInput}
                  placeholder="District Phone"
                  id="districtPhone"
                />
                <Field
                  name="districtFax"
                  component={this.renderInput}
                  placeholder="District Fax"
                  id="districtFax"
                />
                <Field
                  name="districtEmailAddress"
                  component={this.renderInput}
                  placeholder="District Email Address"
                  id="districtEmailAddress"
                />
                <Field
                  name="districtWebPageAddress"
                  component={this.renderInput}
                  placeholder="District Web Page Address"
                  id="districtWebPageAddress"
                />
                <Field
                  name="districtSuperintendent"
                  component={this.renderInput}
                  placeholder="District Superintendent"
                  id="districtSuperintendent"
                />
                <Field
                  name="districtEnrollment"
                  component={this.renderInput}
                  placeholder="District Enrollment"
                  id="districtEnrollment"
                />
              </div>
            </div>
          </div>

          <div className={'formButtonContainer'}>
            <br/>
            <button className="button button__disabled">Submit</button>
          </div>
        </form>
    )
  }
}

const mapStateToProps = ({ sessionToken }) => {
  return { sessionToken }
}

const validate = formValues => {
  const errors = {}

  return errors
}

export default compose(
  connect(
    mapStateToProps,
    { createSchool }
  ),
  reduxForm({ form: 'createSchool', validate })
)(AdminCreateSchoolPage)
