import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { deleteAccount, disableAccount, createAdmin, enableAccount, sendPasswordReset } from "../actions/accounts"

class AccountRow extends Component {
  renderDeleteButton (account) {
    if (account.disabled) {
      return (
        <div>
          <strong style={{ color: 'red' }}
                  onClick={() => this.props.deleteAccount(account.uuid, this.props.sessionToken.sessionToken)}>DELETE</strong> |
          <strong style={{ color: 'green' }}
                  onClick={() => this.props.enableAccount(account.uuid, this.props.sessionToken.sessionToken)}> ENABLE</strong>
        </div>
      )
    } else {
      return (
        <div>
          <strong style={{ color: 'teal' }}
                  onClick={() => this.props.disableAccount(account.uuid, this.props.sessionToken.sessionToken)}>DISABLE</strong>
        </div>
      )
    }
  }

  renderAdminButton (account) {
    if (this.props.admins && this.props.admins[account.uuid]) {
      return (
        <div>
          <strong style={{ color: 'red' }} onClick={() => console.log('Delete Me')}>ADMIN</strong>
        </div>
      )
    } else {
      return (
        <div>
          <strong style={{ color: 'teal' }}
                  onClick={() => this.props.createAdmin(this.props.sessionToken.sessionToken, account.uuid)}>BASIC</strong>
        </div>
      )
    }
  }

  renderResetPasswordButton (account) {
    return (
      <div>
        <strong id={'#resetPassword'} style={{ color: 'red' }} onClick={() => this.props.sendPasswordReset(this.props.sessionToken.sessionToken, account.uuid,)}>RESET
          PASSWORD</strong>
      </div>
    )
  }

  render () {
    const { indexId, account } = this.props
    return (
      <tr key={indexId}>
        <td>{!!account ? account.rosUserUuid ? 'true' : '' : 'n/a'}</td>
        <td>{!!account ? account.email : 'n/a'}</td>
        <td>{!!account ? account.phone : 'n/a'}</td>

        <td>{!!account ? this.renderAdminButton(account) : <div/>}</td>
        <td>{!!account ? account.disabled : 'n/a'}</td>
        <td>{!!account ? this.renderDeleteButton(account) : <div/>}</td>
        <td>{!!account ? this.renderResetPasswordButton(account) : <div/>}</td>
      </tr>
    )
  }
}

const propTypes = {
  indexId: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  renderAdminButton: PropTypes.func,
  renderDeleteButton: PropTypes.func,
  renderResetPasswordButton: PropTypes.func
}

AccountRow.propTypes = propTypes

const mapStateToProps = ({ admins, sessionToken }) => {
  return { admins, sessionToken }
}

export default connect(
  mapStateToProps,
  { deleteAccount, disableAccount, createAdmin, enableAccount, sendPasswordReset }
)(AccountRow)
