import React from 'react'

class InputField extends React.Component {
  render () {
    const { name, title, onEdit, value } = this.props
    return (
      <div className='reduxFormInput' style={{ flex: 1 }}>
        <input
          className="inputWithTitle"
          placeholder={title}
          name={name}
          onChange={onEdit}
          value={value}
        />
      </div>
    )
  }
}

export default InputField
