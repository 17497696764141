import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import account from './account'
import accounts from './accounts'
import admins from './admins'
import incidentReducer from 'reducers/incident'
import invitations from './invitations'
import followupsReducer from 'reducers/followups'
import paginateReducer from 'reducers/pagination'
import schools from './schools'
import sessionToken from './sessionToken'
import students from './students'
import toast from './toast'
import application from './application'

export default combineReducers({
  account,
  accounts,
  admins,
  form: formReducer,
  incidents: incidentReducer,
  invitations,
  followups: followupsReducer,
  paginatedIncidents: paginateReducer,
  schools,
  sessionToken,
  students,
  toast,
  application
})
