import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  header: PropTypes.string,
  message: PropTypes.string,
}

class SubmitError extends React.Component {
  render () {
    if (this.props.header || this.props.message) {
      return (
        <div className={'submitError'}>
          <h5>{this.props.header || 'Error'}</h5>
          <p className={'submitErrorMessage'}>{this.props.message || 'Oops, something went wrong!'}</p>
        </div>
      )
    } else {
      return null
    }
  }
}

SubmitError.propTypes = propTypes

export default SubmitError
