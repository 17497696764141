import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { fetchInvitations, resendInvitation, deleteInvitation } from "../actions/invitations"

import Pagination from '../components/Pagination'

class AdminPendingInvitations extends React.Component {
  constructor () {
    super()

    this.state = {
      loading: true,
      pageOfItems: []
    }

    this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount () {
    const sessionToken = this.props.sessionToken.sessionToken
    this.props.fetchInvitations(sessionToken)
    this.setState({ loading: this.props.invitations.length > 0 })
  }

  onChangePage (pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  renderActionButton (invitation) {
    return (
      <div>
        <strong style={{color: 'red'}} onClick={() => this.props.deleteInvitation(invitation.uuid, this.props.sessionToken.sessionToken)}>DELETE</strong> |
        <strong style={{color: 'green'}} onClick={() => this.props.resendInvitation(invitation.uuid, this.props.sessionToken.sessionToken)}> RESEND INVITATION</strong>
      </div>
    )
  }

  renderTable () {
    return (
      <div>
        <table className="">
          <thead className="incidentsTableHeader">
          <tr>
            <th scope="col">EMAIL</th>
            <th scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>
          {this.state.pageOfItems.map((invitation, i) =>
            <tr key={i}>
              <th>{!!invitation ? invitation.email : 'n/a'}</th>
              <th>{!!invitation ? this.renderActionButton(invitation) : <div/>}</th>
            </tr>
          )}
          </tbody>
        </table>
        <Pagination items={this.props.invitations} onChangePage={this.onChangePage}/>
      </div>
    )
  }

  render () {
    return (
      <div className="incidentsContainer">
        <div className="incidentsTable">{this.renderTable()}</div>
      </div>
    )
  }
}

const mapStateToProps = ({ invitations, paginatedIncidents, sessionToken }) => {
  return { invitations, paginatedIncidents, sessionToken }
}

export default compose(
  connect(
    mapStateToProps,
    { fetchInvitations, deleteInvitation, resendInvitation }
  )
)(AdminPendingInvitations)
