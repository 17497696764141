const sessionToken = (state = { admin: false }, action) => {
  switch(action.type) {
    case 'SET_ACCOUNT':
      const { account, admin } = action
      return {
        uuid: account.uuid,
        email: account.email,
        rosUserUuid: account.rosUserUuid,
        phone: account.phone,
        passwordHash: account.passwordHash,
        resetToken: account.resetToken,
        resetTokenExpiresAt: account.resetTokenExpiresAt,
        resetAttemptCount: account.resetAttemptCount,
        disabled: account.disabled,
        createdAt: account.createdAt,
        updatedAt: account.updatedAt,
        admin: {
          uuid: admin ? admin.uuid : null,
          accountUuid: admin ? admin.accountUuid : null,
          type: admin ? admin.type : null,
          createdAt: admin ? admin.createdAt : null,
          updatedAt: admin ? admin.updatedAt : null
        }
      }
    case 'SET_ACCOUNT_ACCESS':
      return {...state, admin: action.admin }
    case 'DESTROY_SESSION_TOKEN':
      return { admin: false }
    default:
      return state
  }
}

export default sessionToken
