import React from 'react'
import { Router, Route } from 'react-router-dom'

import AdminPage from 'pages/AdminDashboard'
import AdminInviteUserPage from 'pages/AdminInviteUser'
import AdminCreateSchoolPage from 'pages/AdminCreateSchool'
import AdminPendingInvitations from 'pages/AdminPendingInvitations'
import Header from 'components/Header'
import Login from 'pages/Login'
import SignUp from 'pages/SignUp'
import PasswordReset from 'pages/PasswordReset'
import IncidentList from 'pages/IncidentList'
import IncidentCreate from 'pages/IncidentCreate'
import IncidentDetail from 'pages/IncidentDetail'
import SideMenu from 'components/SideMenu'
import AdminAllUsers from 'pages/AdminAllUsers'

import history from './history'
import { connect } from "react-redux"
import { getSessionFromToken } from "./actions/sessions"

class App extends React.Component {
  render () {
    return (
      <div id="applicationContainer">
        <Router history={history}>
          <Header/>
          <div className="componentContainer">
            <SideMenu/>
            <div className="componentSection">
              <Route path="/" exact component={IncidentList}/>
              <Route path="/login" exact component={Login}/>
              <Route path="/invitations/:invitationCode/:email" exact component={SignUp}/>
              <Route path="/passwordReset/:resetToken/:email" exact component={PasswordReset}/>
              <Route path="/new" exact component={IncidentCreate}/>
              <Route path="/detail/:uuid" exact component={IncidentDetail}/>
              <Route path="/admin" exact component={AdminPage}/>
              <Route path="/inviteUser" exact component={AdminInviteUserPage}/>
              <Route path="/createSchool" exact component={AdminCreateSchoolPage}/>
              <Route path="/allUsers" exact component={AdminAllUsers}/>
              <Route path="/pendingInvitations" exact component={AdminPendingInvitations}/>
            </div>
          </div>
        </Router>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    sessionToken: state.sessionToken
  }
}

function mapDispatchToProps (dispatch) {
  return {
    validateSession: (token) => dispatch(getSessionFromToken(token))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
