export default function(state = [], action) {
  switch (action.type) {
    case 'SET_ACCOUNTS':
      return action.accounts
    case 'CLEAR_ACCOUNTS':
      return null
    default:
      return state
  }
}
