import React from 'react'
import { connect } from 'react-redux'

import SubmitError from 'components/SubmitError'
import InputField from "./InputField"
import InputDate from "./InputDate"
import InputSchool from "./InputSchool"

import { fetchSchools } from "../actions/schools"

class IncidentForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      peims: '',
      firstName: '',
      lastName: '',
      gender: '',
      ethnicity: '',
      race: '',
      weight: null,
      height: null,
      guardianEmail: '',
      school: undefined,
      inputText: '',
      dob: null,
      incidentDate: null,
      firstEvaluation: null,
      sport: '',
      yearsInSport: '',
      event: '',
      teamLevel: '',
      field: '',
      grade: '',
      headNeckContact: '',
      numberOfPriorConcussions: null,
      lossOfConsciousness: false,
      wearingHelmet: false,
      errors: {}
    }
  }

  componentDidMount () {
    const { values } = this.props
    if (values) {
      this.setState({
        ...values,
        dob: values.dob ? new Date(values.dob) : null,
        incidentDate: values.incidentDate ? new Date(values.incidentDate) : null,
        firstEvaluation: values.firstEvaluation ? new Date(values.firstEvaluation) : null,
        school: { schoolName: values.schoolName },
        inputText: values.schoolName
      })
    }
  }

  handleSubmit = () => {
    const errors = this.validate(this.state)
    if (errors.length === 0) {
      this.props.onSubmit(this.state)
    } else {
      this.setState({ errors })
    }
  }

  validate = state => {
    const errors = []
    const {
      peims,
      // firstName,
      // lastName,
      gender,
      ethnicity,
      race,
      // weight,
      // height,
      // guardianEmail,
      school,
      dob,
      incidentDate,
      firstEvaluation,
      sport,
      // yearsInSport,
      event,
      // teamLevel,
      field,
      grade,
      headNeckContact,
      numberOfPriorConcussions,
      // lossOfConsciousness,
      // wearingHelmet
    } = state

    if (!peims || peims.length === 0) errors.push('Must enter a PEIMS number')
    if (!gender || gender.length === 0) errors.push('Must enter a gender')
    if (!ethnicity || ethnicity.length === 0) errors.push('Must enter an ethnicity')
    if (!race || race.length === 0) errors.push('Must enter a race')
    if (!dob) errors.push('Must enter a date of birth')
    if (!incidentDate) errors.push('Must enter a incident date')
    if (!firstEvaluation) errors.push('Must enter a date of first evaluation')
    if (!sport || sport.length === 0) errors.push('Must enter a sport')
    if (!event || event.length === 0) errors.push('Must enter an event')
    if (!field || field.length === 0) errors.push('Must enter a field location')
    if (!grade || grade.length === 0) errors.push('Must enter a grade')
    if (!headNeckContact || headNeckContact.length === 0) errors.push('Must enter a description for head and neck contact')
    if (numberOfPriorConcussions === null || numberOfPriorConcussions === undefined || numberOfPriorConcussions.length === 0) errors.push('Must enter number of prior concussions')
    if (!school || (school && Object.keys(school).length === 0)) errors.push('Must select a school')
    return errors
  }

  chooseSchool = school => {
    const inputText = school ? school.schoolName : ''
    this.setState({ school, inputText })
  }

  searchSchools = (value) => {
    this.setState({ school: null, inputText: value })
    this.props.fetchSchools(value)
  }

  renderInputs = () => {
    return (
      <div style={{ flex: 1, maxWidth: 800 }}>
        <div className="sectionTitle">
          <p className="appText text__md">Student and Incident Information</p>
        </div>
        <div className="reduxFormRow">
          <InputField
            name={'peims'}
            title={'PEIMS'}
            onEdit={event => this.setState({ peims: event.target.value.replace(/\D/, '') })}
            value={this.state.peims}
          />
        </div>
        <div className="reduxFormRow">
          <InputField
            name={'firstName'}
            title={'FIRST NAME (optional)'}
            onEdit={event => this.setState({ firstName: event.target.value })}
            value={this.state.firstName}
          />
          <InputField
            name={'lastName'}
            title={'LAST NAME (optional)'}
            onEdit={event => this.setState({ lastName: event.target.value })}
            value={this.state.lastName}
          />
        </div>
        <div className="reduxFormRow">
          <div style={{ flex: 1 }}>
            <select
              name="gender"
              id="gender"
              className="dropdownInput"
              style={{ width: '200px' }}
              onChange={event => this.setState({ gender: event.target.value })}
              value={this.state.gender}
            >
              <option>Choose...</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            <span className="inputWithTitle__title">GENDER</span>
          </div>
          <div style={{ flex: 1 }}>
            <select
              name="ethnicity"
              id="ethnicity"
              className="dropdownInput"
              style={{ width: '200px' }}
              onChange={event => this.setState({ ethnicity: event.target.value })}
              value={this.state.ethnicity}
            >
              <option>Choose...</option>
              <option value="hispanic">Hispanic or Latino</option>
              <option value="notHispanic">Not Hispanic or Latino</option>
            </select>
            <span className="inputWithTitle__title">ETHNICITY</span>
          </div>
          <div style={{ flex: 1 }}>
            <select
              name="race"
              id="race"
              className="dropdownInput"
              style={{ width: '200px' }}
              onChange={event => this.setState({ race: event.target.value })}
              value={this.state.race}
            >
              <option>Choose...</option>
              <option value="americanIndian">American Indian or Alaska Native</option>
              <option value="asian">Asian</option>
              <option value="black">Black or African American</option>
              <option value="nativeHawaiian">
                Native Hawaiian or Other Pacific Islander
              </option>
              <option value="white">White</option>
            </select>
            <span className="inputWithTitle__title">RACE</span>
          </div>
        </div>
        <div className="reduxFormRow">
          <InputField
            name={'weight'}
            title={'WEIGHT IN POUNDS (optional)'}
            onEdit={event => this.setState({ weight: event.target.value.replace(/\D/, '') })}
            value={this.state.weight}
          />
          <InputField
            name={'height'}
            title={'HEIGHT IN INCHES (optional)'}
            onEdit={event => this.setState({ height: event.target.value.replace(/\D/, '') })}
            value={this.state.height}
          />
        </div>
        <div className="reduxFormRow">
          <InputField
            name={'guardianEmail'}
            title={'PARENT/GUARDIAN EMAIL (optional)'}
            onEdit={event => this.setState({ guardianEmail: event.target.value })}
            value={this.state.guardianEmail}
          />
        </div>
        <div className="reduxFormRow">
          <InputSchool
            school={this.state.school}
            schools={this.props.schools}
            inputText={this.state.inputText}
            chooseSchool={this.chooseSchool}
            searchSchools={this.searchSchools}
          />
        </div>
        <div className="reduxFormRow" style={{ }}>
          <div style={{ flex: 1 }}>
            <InputDate name={'dob'} title={'DATE OF BIRTH'} selected={this.state.dob}
                       onChange={dob => this.setState({ dob }) } />
          </div>
          <div style={{ flex: 1 }}>
            <InputDate name={'incidentDate'} title={'INCIDENT DATE'} selected={this.state.incidentDate}
                       onChange={incidentDate => this.setState({ incidentDate }) } />
          </div>
          <div style={{ flex: 1 }}>
            <InputDate name={'firstEvaluation'} title={'FIRST EVALUATION'} selected={this.state.firstEvaluation}
                       onChange={firstEvaluation => this.setState({ firstEvaluation }) } />
          </div>
        </div>
        <div className="reduxFormRow">
          <InputField
            name={'sport'}
            title={'SPORT'}
            onEdit={event => this.setState({ sport: event.target.value })}
            value={this.state.sport}
          />
          <InputField
            name={'yearsInSport'}
            title={'YEARS IN SPORT (optional)'}
            onEdit={event => this.setState({ yearsInSport: event.target.value.replace(/\D/, '') })}
            value={this.state.yearsInSport}
          />
        </div>
        <div className="reduxFormRow">
          <InputField
            name={'event'}
            title={'EVENT TYPE'}
            onEdit={event => this.setState({ event: event.target.value })}
            value={this.state.event}
          />
          <InputField
            name={'teamLevel'}
            title={'TEAM LEVEL (optional)'}
            onEdit={event => this.setState({ teamLevel: event.target.value })}
            value={this.state.teamLevel}
          />
        </div>
        <div className="reduxFormRow">
          <InputField
            name={'field'}
            title={'FIELD LOCATION'}
            onEdit={event => this.setState({ field: event.target.value })}
            value={this.state.field}
          />
          <InputField
            name={'grade'}
            title={'GRADE'}
            onEdit={event => this.setState({ grade: event.target.value.replace(/\D/, '') })}
            value={this.state.grade}
          />
        </div>
        <div className="reduxFormRow">
          <InputField
            name={'headNeckContact'}
            title={'WHAT MADE CONTACT WITH HEAD/NECK'}
            onEdit={event => this.setState({ headNeckContact: event.target.value })}
            value={this.state.headNeckContact}
          />
        </div>
        <div className="reduxFormRow checkboxInput" style={{ marginLeft: 16 }}>
          <label style={{ margin: '0px' }}>Was loss of consciousness? &nbsp;</label>
          <div>
            <input type="checkbox" checked={this.state.lossOfConsciousness} onChange={() => this.setState({ lossOfConsciousness: !this.state.lossOfConsciousness }) }/>
          </div>
        </div>
        <div className="reduxFormRow checkboxInput" style={{ marginLeft: 16 }}>
          <label style={{ margin: '0px' }}>Was wearing a helmet? &nbsp;</label>
          <div>
            <input type="checkbox" checked={this.state.wearingHelmet} onChange={() => this.setState({ wearingHelmet: !this.state.wearingHelmet }) }/>
          </div>
        </div>
        <div className="reduxFormRow" style={{ marginTop: 0 }}>
          <InputField
            name={'numberOfPriorConcussions'}
            title={'Number of prior concussions'}
            onEdit={event => this.setState({ numberOfPriorConcussions: event.target.value.replace(/\D/, '') })}
            value={this.state.numberOfPriorConcussions}
          />
        </div>
      </div>
    )
  }

  renderFooterArea = () => {
    return (
      <div className="incidentsSave">
        {this.state.errors.length &&
        <div style={{ margin: 'auto' }}>
          <SubmitError header={''}
                       message={this.state.errors[0]}/>
        </div>
        }
        <div>
          <button
            className="button"
            style={{ marginTop: 35 }}
            onClick={e => {
              e.preventDefault()
              this.handleSubmit()
            }}>
            Save
          </button>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className="incidentContainer">
        <form style={{ display: 'inline-block' }}>
          {this.renderInputs()}
          {this.renderFooterArea()}
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ toast, schools }) => {
  return { toast, schools }
}

export default connect(mapStateToProps, { fetchSchools })(IncidentForm)
