import React from 'react'
import { connect } from 'react-redux'

import { createIncident } from '../actions/incidents'

import IncidentForm from '../components/Incident'

class IncidentCreateForm extends React.Component {
  onSubmit = formProps => {
    this.props.createIncident(
      this.props.sessionToken.sessionToken,
      { formProps },
      () => this.props.history.push('/')
    )
  }

  render () {
    return <IncidentForm onSubmit={this.onSubmit} />
  }
}

const mapStateToProps = ({ sessionToken }) => {
  return { sessionToken }
}

export default connect(mapStateToProps, { createIncident })(IncidentCreateForm)
