export const SHOW_SIDE_MENU = 'SHOW_SIDE_MENU'
export const HIDE_SIDE_MENU = 'HIDE_SIDE_MENU'
export const SHOW_HEADER = 'SHOW_HEADER'
export const HIDE_HEADER = 'HIDE_HEADER'

export function toggleHeader (value) {
  return function (dispatch) {
    if (!!value) dispatch(showHeader())
    if (!value) dispatch(hideHeader())
  }
}

export function toggleSideMenu (value) {
  return function (dispatch) {
    if (!!value) dispatch(showSideMenu())
    if (!value) dispatch(hideSideMenu())
  }
}

export function showHeader () {
  return {
    type: SHOW_HEADER,
    requestedAt: Date.now(),
  }
}

export function hideHeader () {
  return {
    type: HIDE_HEADER,
    requestedAt: Date.now(),
  }
}

export function showSideMenu () {
  return {
    type: SHOW_SIDE_MENU,
    requestedAt: Date.now(),
  }
}

export function hideSideMenu () {
  return {
    type: HIDE_SIDE_MENU,
    requestedAt: Date.now(),
  }
}
