import * as API from '../services/CONTEX-API'
export const SET_STUDENTS = 'SET_STUDENTS'
export const REQUEST_STUDENTS = 'REQUEST_STUDENTS'
export const STUDENT_REQUEST_FAILED = 'STUDENT_REQUEST_FAILED'

export function fetchStudents (sessionToken) {
  return function (dispatch, getState) {
    dispatch(requestStudents())

    API.getStudents(sessionToken)
      .then(students => {
        dispatch(setStudents(students))
      })
      .catch(error => dispatch(requestFailed(error)))
  }
}

export function requestStudents () {
  return {
    type: REQUEST_STUDENTS,
    requestedAt: Date.now(),
  }
}

export function setStudents (students) {
  return {
    type: SET_STUDENTS,
    students,
    receivedAt: Date.now(),
  }
}

export function requestFailed (status) {
  console.info(status)
  return {
    type: STUDENT_REQUEST_FAILED,
    status,
    received_at: Date.now(),
  }
}