
export default function(state = [], action) {
  switch (action.type) {
    case 'SET_FOLLOW_UPS':
      return action.followUps
    case 'FETCH_FOLLOWUPS':
      return action.payload
    default:
      return state
  }
}
