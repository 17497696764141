import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from 'Root'
import App from 'App'

require('./assets/styles/styles.css')

ReactDOM.render(
  <Root>
    <App/>
  </Root>,
  document.getElementById('root')
)
