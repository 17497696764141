import React from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class InputDate extends React.Component {
  render() {
    const { selected, onChange, title } = this.props
    return (
      <div className="pickerInput" style={{ flex: 1 }}>
        <DatePicker dateForm="MM-DD-YYYY" selected={selected} onChange={onChange}/>
        <span className="inputWithTitle__title">{title}</span>
      </div>
    )
  }
}

export default InputDate
