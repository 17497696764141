import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { login } from '../actions/sessions'

import background from '../assets/images/photo-1508354486999-d33bd3cecc4c.jpeg'

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Please enter your Rank One Sport email address'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  return errors
}

class Login extends React.Component {
  onSubmit = formProps => {
    this.props.login(formProps, () => {
      this.props.history.push('/')
    })
  }

  determineEnv() {
    const env = document.location.origin
    switch (true) {
      case RegExp('contexuat', 'g').test(env):
        return 'ConTex UAT'
      case RegExp('localhost', 'g').test(env):
        return 'ConTex Dev'
      case RegExp('contex', 'g').test(env):
        return 'ConTex'
      default:
        return 'ConTex'
    }
  }

  renderError ({ error, touched }) {
    if (touched && error) {
      return (
        <div className="formError">
          <div className="formError__text">{error}</div>
        </div>
      )
    }
  }

  renderInput = ({ input, placeholder, meta, id, inputType }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`
    return (
      <div className={className}>
        <input
          {...input}
          placeholder={placeholder}
          type={inputType}
          className="formInput"
          id={id}
        />
        {this.renderError(meta)}
      </div>
    )
  }

  render () {
    const title = this.determineEnv()
    const { handleSubmit } = this.props
    return (
      <div className={'imageBackground'} style={{ backgroundImage: `url(${background})` }}>
        <div className={'overlay__blue'}>
          <form id="loginFormContainer" onSubmit={handleSubmit(this.onSubmit)}>
            <div className={'loginFormGroup'}>
              <div className={'appTitle'}>
                <p>{title}</p>
              </div>
            </div>
            <div className={'loginFormGroup'}>
              <div className="form-group">
                <Field
                  name="email"
                  inputType="text"
                  component={this.renderInput}
                  placeholder="Email Address"
                  id="emailinput"
                />
              </div>
              <div className="form-group">
                <Field
                  name="password"
                  inputType="password"
                  component={this.renderInput}
                  placeholder="Password"
                  id="passwordinput"
                />
              </div>
              <div className="appText errorText">{this.props.errorMessage}</div>
            </div>
            <div className={'loginFormGroup'}>
              <div className={'formButtonContainer'}>
                <button disabled={!this.props.enabledSubmit}
                        className={`button ${ this.props.enabledSubmit ? 'button__enabled' : 'button__disabled'}`}>Sign In
                </button>
                <div style={{width: '100%', marginTop: 52, textAlign: 'center'}}>
                  <a href={'https://www.rankonesport.com/content/terms-conditions/'}>Terms and Conditions</a>
                  <br/>
                  <br/>
                  <a href={'https://www.rankonesport.com/content/privacy-policy/'}>Privacy Policy</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const isValidForm = () => {
    try {
      return state.form.login.values.email && state.form.login.values.password
    } catch {
      return false
    }
  }

  return {
    errorMessage: state.sessionToken ? state.sessionToken.errorMessage : null,
    enabledSubmit: isValidForm(),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    login: (formProps, callback) => dispatch(login(formProps, callback))
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({ form: 'login', validate }),
)(Login)
