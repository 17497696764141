const application = (state = { showHeader: false, showSideMenu: false }, action) => {
  switch(action.type) {
    case 'HIDE_HEADER':
      return { ...state, showHeader: false}
    case 'SHOW_HEADER':
      return { ...state, showHeader: true}
    case 'HIDE_SIDE_MENU':
      return { ...state, showSideMenu: false}
    case 'SHOW_SIDE_MENU':
      return { ...state, showSideMenu: true}
    default:
      return state
  }
}

export default application
