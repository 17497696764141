import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

export default class Row extends React.Component {
  render () {
    const { item, indexId, student } = this.props;
    let athlete
    if (!!student && student.firstName && student.lastName) {
      athlete = `${student.firstName} ${student.lastName}`
    } else {
      athlete = 'n/a'
    }
    return (
      <tr key={indexId} onClick={() => this.props.history.push(`/detail/${item.uuid}`)}>
        <th>{!!student ? student.peims : 'n/a'}</th>
        <th>{athlete}</th>
        <th>{!!student ? moment(student.dob).format("MMM DD YYYY") : 'n/a'}</th>
        <th>{!!item ? moment(item.incidentDate).format("MMM DD YYYY") : 'n/a'}</th>
        <th>{!!item ? item.status : 'n/a'}</th>
        <th>
          <Link to={'/'}>Edit</Link>
        </th>
      </tr>
    )
  }
}
