import * as API from '../services/CONTEX-API'

import { fetchIncidents } from "./incidents"

export const CLEAR_ACCOUNTS = 'CLEAR_ACCOUNTS'
export const CLEAR_TOAST = 'CLEAR_TOAST'
export const GET_ACCOUNT = 'GET_ACCOUNT'
export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const INVITE_USER = 'INVITE_USER'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'
export const SET_ADMINS = 'SET_ADMINS'
export const SET_ACCOUNT_ACCESS = 'SET_ACCOUNT_ACCESS'
export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN'
export const DESTROY_SESSION_TOKEN = 'DESTROY_SESSION_TOKEN'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DISABLE_ACCOUNT = 'DISABLE_ACCOUNT'
export const ENABLED_ACCOUNT = 'ENABLED_ACCOUNT'
export const ACCOUNT_REQUEST_FAILED = 'ACCOUNT_REQUEST_FAILED'
export const EMAIL_INVITE_SUCCESS = 'EMAIL_INVITE_SUCCESS'
export const EMAIL_INVITE_FAILURE = 'EMAIL_INVITE_FAILURE'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUBMIT = 'RESET_PASSWORD_SUBMIT'

export function createAccount (formProps, email, invitationCode, callback) {
  return function (dispatch) {
    API.createAccount(email, formProps.password, formProps.confirmPassword, formProps.firstName, formProps.lastName, invitationCode)
      .then(account => {
        callback()
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}

export function createAdmin (sessionToken, accountUuid) {
  return function (dispatch) {
    API.createAdmin(sessionToken, accountUuid)
      .then(() => {
        dispatch(fetchAccounts(sessionToken))
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}

export function getAccount (sessionToken) {
  return function (dispatch) {
    dispatch(getAccountDetails)
    API.getAccount(sessionToken)
      .then(account => {
        dispatch(setAccount(account.account, account.admin))
        dispatch(fetchIncidents(sessionToken, account.account.uuid))
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}

export function fetchAccounts (sessionToken) {
  return function (dispatch) {
    dispatch(getAccounts)
    API.getAccounts(sessionToken)
      .then(response => {
        dispatch(setAccounts(response.accounts))
        dispatch(setAdmins(response.admins))
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}

export function deleteAccount (uuid, sessionToken) {
  return function (dispatch) {
    API.deleteAccount(uuid, sessionToken)
      .then(() => {
        dispatch(deletedAccountSuccess)
        dispatch(fetchAccounts(sessionToken))
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}

export function disableAccount (uuid, sessionToken) {
  return function (dispatch) {
    API.disableAccount(uuid, sessionToken)
      .then(() => {
        dispatch(disabledAccountSuccess)
        dispatch(fetchAccounts(sessionToken))
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}

export function enableAccount (uuid, sessionToken) {
  return function (dispatch) {
    API.enableAccount(uuid, sessionToken)
      .then(() => {
        dispatch(enabledAccountSuccess)
        dispatch(fetchAccounts(sessionToken))
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}

export function inviteUser (formProps, callback) {
  return function (dispatch, getState) {
    dispatch(createAccountInvitation)
    API.inviteUser(formProps.email, getState().account.uuid, getState().sessionToken.sessionToken)
      .then(response => {
        dispatch(emailInviteSuccess(response))
        callback()
        setTimeout(function () {
          dispatch(clearToast())
        }, 3000)
      })
      .catch(error => {
        dispatch(emailInviteFailure(error))
        setTimeout(function () {
          dispatch(clearToast())
        }, 6000)
      })
  }
}


export function sendPasswordReset (uuid, sessionToken) {
  return function (dispatch) {
    dispatch(sendPasswordResetInstructions())
    API.sendPasswordReset(uuid, sessionToken)
      .then((message) => {
        dispatch(sendPasswordResetComplete(message))
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}

export function submitPasswordReset (formProps, email, resetToken, callback) {
  return function (dispatch) {
    dispatch(updateAccount())
    API.resetPassword(formProps.password, formProps.confirmPassword, email, resetToken)
      .then((message) => {
        dispatch(sendPasswordResetComplete(message))
        callback()
      })
      .catch(error => {
        dispatch(requestFailed(error))
      })
  }
}

function updateAccount () {
  return {
    type: 'UPDATE_ACCOUNT',
    receivedAt: Date.now()
  }
}

function sendPasswordResetInstructions () {
  return {
    type: RESET_PASSWORD,
    receivedAt: Date.now(),
  }
}

export function sendPasswordResetComplete (message) {
  return {
    type: RESET_PASSWORD,
    receivedAt: Date.now(),
    message
  }
}

export function dismissToast () {
  return function (dispatch) {
    dispatch(clearToast())
  }
}

export function getAccountDetails () {
  return {
    type: GET_ACCOUNT,
    receivedAt: Date.now(),
  }
}

export function getAccounts () {
  return {
    type: GET_ACCOUNTS,
    receivedAt: Date.now(),
  }
}

export function setAccounts (accounts) {
  return {
    type: SET_ACCOUNTS,
    accounts,
    receivedAt: Date.now(),
  }
}

export function setAdmins (admins) {
  return {
    type: SET_ADMINS,
    admins,
    receivedAt: Date.now(),
  }
}

export function deletedAccountSuccess () {
  return {
    type: DELETE_ACCOUNT,
    receivedAt: Date.now(),
  }
}

export function disabledAccountSuccess () {
  return {
    type: DISABLE_ACCOUNT,
    receivedAt: Date.now(),
  }
}

export function enabledAccountSuccess () {
  return {
    type: ENABLED_ACCOUNT,
    receivedAt: Date.now(),
  }
}

export function clearAccounts () {
  return {
    type: CLEAR_ACCOUNTS,
    receivedAt: Date.now(),
  }
}

export function createAccountInvitation () {
  return {
    type: INVITE_USER,
    receivedAt: Date.now(),
  }
}

export function setAccount (account, admin) {
  return {
    type: SET_ACCOUNT,
    account,
    admin,
    receivedAt: Date.now(),
  }
}

export function emailInviteSuccess (status) {
  console.info(status)
  return {
    type: EMAIL_INVITE_SUCCESS,
    message: `Successfully invited ${status.email}`,
    received_at: Date.now(),
  }
}

export function emailInviteFailure (status) {
  console.info(status)
  return {
    type: EMAIL_INVITE_FAILURE,
    message: `Failed to send invitation!`,
    received_at: Date.now(),
  }
}

export function clearToast () {
  return {
    type: CLEAR_TOAST
  }
}

export function requestFailed (status) {
  console.info(status)
  return {
    type: ACCOUNT_REQUEST_FAILED,
    status,
    received_at: Date.now(),
  }
}
