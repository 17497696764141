import * as API from '../services/CONTEX-API'
import history from "../history"
import { hideHeader, hideSideMenu, toggleHeader, toggleSideMenu } from "./application"
import { getAccount } from "./accounts"

export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN'
export const SET_SESSION_TOKEN_FAILED = 'SET_SESSION_TOKEN_FAILED'
export const DESTROY_SESSION_TOKEN = 'DESTROY_SESSION_TOKEN'
export const REQUEST_SESSION_TOKEN = 'REQUEST_SESSION_TOKEN'
export const SESSION_TOKEN_REQUEST_FAILED = 'SESSION_TOKEN_REQUEST_FAILED'

export function login (formProps, callback) {
  return function (dispatch) {
    dispatch(requestSessionToken())
    API.getSession(formProps.email, formProps.password)
      .then(response => {
        if (response) {
          dispatch(setSessionToken(response))
          dispatch(getAccount(response.sessionToken))
          dispatch(toggleHeader(true))
          dispatch(toggleSideMenu(true))
        } else {
          dispatch(setSessionTokenFailed(response))
        }
        callback()
      })
      .catch(error => {
        console.log(error)
        dispatch(requestFailed(error))
      })
  }
}

export const logout = () => {
  return function (dispatch, getState) {
    try {
      if (getState().sessionToken) {
        const token = getState().sessionToken.sessionToken
        API.destroySession(token)
          .then(() => {
            dispatch(destroySessionToken())
          })
      }
    } catch (error) {
      dispatch(requestFailed(error))
    } finally {
      dispatch(hideHeader())
      dispatch(hideSideMenu())
      history.push('/login')
    }
  }
}

export const logoutNoRedirect = () => {
  return function (dispatch, getState) {
    try {
      if (getState().sessionToken) {
        const token = getState().sessionToken.sessionToken
        API.destroySession(token)
          .then(() => {
            dispatch(destroySessionToken())
          })
      }
    } catch (error) {
      dispatch(requestFailed(error))
    } finally {
      dispatch(hideHeader())
      dispatch(hideSideMenu())
    }
  }
}

export const getSessionFromToken = (token) => {
  return function (dispatch) {
    dispatch(requestSessionToken())
    if (!token) {
      dispatch(requestFailed({ error: 'NoToken' }))
      dispatch(hideHeader())
      dispatch(hideSideMenu())
      dispatch(destroySessionToken())
      history.push('/login')
    } else {
      API.getSessionFromToken(token)
        .then(response => {
          if (response.sessionToken) {
            dispatch(setSessionToken(response))
            dispatch(getAccount(response.sessionToken))
            history.push('/')
          }
        }).catch(err => {
          dispatch(setSessionTokenFailed(err))
          dispatch(hideHeader())
          dispatch(hideSideMenu())
          dispatch(destroySessionToken())
          history.push('/login')
      })
    }
  }
}

export function requestSessionToken () {
  return {
    type: REQUEST_SESSION_TOKEN,
    requestedAt: Date.now(),
  }
}

export function setSessionToken (authorization) {
  return {
    type: SET_SESSION_TOKEN,
    sessionToken: authorization.sessionToken,
    receivedAt: Date.now(),
  }
}

export function setSessionTokenFailed (authorization) {
  return {
    type: SET_SESSION_TOKEN_FAILED,
    authorization,
    receivedAt: Date.now(),
  }
}

export function destroySessionToken () {
  return {
    type: DESTROY_SESSION_TOKEN,
    receivedAt: Date.now(),
  }
}

export function requestFailed (status) {
  console.info(status)
  return {
    type: SESSION_TOKEN_REQUEST_FAILED,
    status,
    received_at: Date.now(),
  }
}
