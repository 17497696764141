import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../actions/sessions'
import { getAccount } from "../actions/accounts"

class Header extends React.Component {
  componentDidMount () {
    if (this.props.sessionToken && this.props.sessionToken.sessionToken && !this.props.account) {
      this.props.getAccount(this.props.sessionToken.sessionToken)
    }
  }

  render () {
    const welcomeTag = (
      this.props.account ?
        <span className="appText text__md text__right">{`${this.props.account.email}`}</span> :
        <span className="appText text__md text__right">Welcome User!</span>
    )
    if (this.props.application.showHeader) {
      return (
        <div className="header">
          <div className="logo">
            <Link to={"/"} className="appText logo__text">ConTex</Link>
          </div>
          <div className="profile">
            { welcomeTag }
            <div onClick={() => this.props.logout()} className="linkText text__right">
              Logout
            </div>
          </div>
        </div>
      )
    } else {
      return <div/>
    }
  }
}

const mapStateToProps = state => {
  return { account: state.account, application: state.application }
}

export default connect(
  mapStateToProps,
  { getAccount, logout }
)(Header)
